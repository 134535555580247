@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
.new-link {
  text-decoration: none;
}
.NewSelect {
  border-top: none;
  border-left: none;
  border-right: none;
  height: 30px;
  width: 360px;
}

.NewSelect2 {
  border-top: none;
  border-left: none;
  border-right: none;
  height: 30px;
  width: 60px;
}
p {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
a {
  text-decoration: none;
}
body {
  font-family: "Inter", sans-serif;
  position: relative;
  overflow-x: hidden !important;
}
.prevBtn {
  text-decoration: none;
  color: #000;
  margin-left: 25px;
}
.prevBtn:hover {
  text-decoration: none;
  color: #000;
}
.prevBtn img {
  margin-right: 0.5rem;
}
.nextBtn {
  margin-right: 0.5rem;
  text-decoration: none;
  width: 110px;
  background-color: #a20010;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  border: none;
  margin: 4rem 1.5rem 0;
  font-size: 1.125rem;
  text-transform: uppercase;
}
.nextBtn:hover {
  color: #ffffff;
}
.btn-disable {
  background-color: #e0e0e0;
  color: #a6a6a6;
}
@media only screen and (max-width: 992px) {
  html {
    overflow-x: hidden !important;
  }
  .nextBtn {
    width: 80px;
    height: 46px;
    border: none;
    margin: 1rem 0.35rem 0;
    margin-left: 100px;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .ant-form-item {
    margin-right: 0 !important;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  margin: 0 !important;
}

/* Change Whole Label Size
.ant-form-item-label label {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
} */
